import React from 'react';

import ReactPaginate from 'react-paginate';

import { ProductSearchCriteria, Product, SimpleProduct, ProductWarehouseInventoryInformation, ProductPrice } from '@msdyn365-commerce/retail-proxy';
import { searchAsync, getByIdsAsync,  getEstimatedAvailabilityAsync, getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { ICartDataSet, IProductLayoutProps } from './components-interfaces-definition';

import { isMobile } from '../../../components/breakpoints';

import { ProductCard } from '../../../components/ecomm-product-card';

import { IEcommProductListData } from '../ecomm-product-list.data';
import { IEcommProductListProps } from '../ecomm-product-list.props.autogenerated';

export async function getProductListFromCategory(props: IEcommProductListProps<IEcommProductListData>, categories: string[]): Promise<ICartDataSet> {
  const { context } = props;

  const projectDomain = { ChannelId: + context.actionContext.requestContext.apiSettings.channelId, CatalogId: + context.actionContext.requestContext.apiSettings.catalogId };

  let categoriesNum: number[] = [];
  let productSearchCriterias: ProductSearchCriteria = { Context: projectDomain, IncludeAttributes: true };
  let products: Product[];
  let simpleProductsResults: SimpleProduct[] = [];
  let activePricesResult: ProductPrice[] = [];
  let productsAvailabilityResults: ProductWarehouseInventoryInformation = {};
  let productIds: number[] = [];
  let resultObject: ICartDataSet = {};

  if (categories) {
    categoriesNum = categories.map(element => parseInt(element, 10));

    if (categoriesNum.length > 0) {
      productSearchCriterias.CategoryIds = categoriesNum;
    }
  }

  products = await searchAsync({ callerContext: context.actionContext }, productSearchCriterias).catch(error => {
    console.log('[getProductListFromCategory]searchAsync error: ', error);

    return [];
  });

  if (products.length > 0) {
    products.map(product => productIds.push(product.RecordId));
    simpleProductsResults = await getByIdsAsync({ callerContext: context.actionContext }, context.request.apiSettings.channelId, productIds);
    productsAvailabilityResults = await getEstimatedAvailabilityAsync({ callerContext: context.actionContext }, { ProductIds: productIds, DefaultWarehouseOnly: true });
    activePricesResult = await getActivePricesAsync({ callerContext: context.actionContext, queryResultSettings: {} }, projectDomain,productIds, new Date(), null, [], true);
  }

  resultObject = {
    productIds: productIds,
    selectedProducts: products,
    productsSimpleDetails: simpleProductsResults,
    productsAvailability: productsAvailabilityResults,
    activePrices: activePricesResult,
    categoryIds: categoriesNum
  };

  return resultObject;
}

/**
 *
 * BuildProductLayout component
 * @extends {React.PureComponent<IProductLayoutProps>}
 */
export class BuildProductLayout extends React.PureComponent<IProductLayoutProps> {
  public state: any = { products: [], width: 0, activePage: 0, pageSize: 9 };

  public componentDidMount(): void {
    if (window !== undefined) {
      window.addEventListener('resize', this._updateDimensions);

      this.setState({ products: this.props.simpleProductList, width: window.innerWidth });
    }
  }

  public render(): JSX.Element | null {
    if (isMobile(this.state.width) && this.state.pageSize % 2 !== 0) {
      this.setState({ pageSize: 12 });
    }

    const { context, gridSettings, simpleProductList }: any = this.props;

    let offset = this.state.activePage * Number(this.state.pageSize);

    if (JSON.stringify(this.state.products) !== JSON.stringify(simpleProductList)) {
      offset = 0;

      this.setState({ products: simpleProductList, activePage: 0 });
    }

    const currentPageData = simpleProductList.slice(offset, offset + Number(this.state.pageSize)).map((item: SimpleProduct) => <ProductCard
        key={item.RecordId}
        context={context}
        gridSettings={gridSettings}
        product={item}
        hasExpired={false}
        isRegistered={false}
        unavailableText={this.props.unavailableText}
        registeredText={''}
      />);

    const pageCount = Math.ceil(simpleProductList.length / this.state.pageSize);

    return (
      <div className='ecomm-product-list-container'>
        <ul className='ecomm-product-list'>{currentPageData}</ul>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this._handlePageClick}
          containerClassName={'ecomm-pagination'}
          activeClassName={'active'}
          forcePage={this.state.activePage}
        />
      </div>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimensions);
  }

  private _handlePageClick = (data: any) => {
    if (window) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    this.setState({ activePage: data.selected });
  }

  private _updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  }; 
}

export default BuildProductLayout;