/* tslint:disable: no-duplicate-imports */
import { IAddToCartFailureResult } from '@msdyn365-commerce/components';
import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { OrgUnitLocation, ProductAvailableQuantity , ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { IBtrtsBuyboxResources } from '../components/components-interfaces-definition';
import resource from '../resources/resource.json';

export function getQuantityError(stockLeft: number | undefined, resources: IBtrtsBuyboxResources): string | undefined {
    if (!stockLeft || stockLeft <= 0) {
        return resources.errorMessageOutOfStock;
    } else if (stockLeft === 1) {
        return resources.errorMessageOutOfRangeOneLeft;
    } else {
        return resources.errorMessageOutOfRangeFormat.replace('{numLeft}', stockLeft.toString());
    }
}

// tslint:disable-next-line:cyclomatic-complexity
export function getGenericError(
        result: IAddToCartFailureResult,
        cart: ICartState | undefined,
        resources: IBtrtsBuyboxResources,
        context: ICoreContext<IGeneric<IAny>>,
        product: SimpleProduct | undefined,
        productAvailability: ProductAvailableQuantity  | undefined,
        location: OrgUnitLocation | undefined)
    : string | undefined {
        const localeCode = context.request.locale.split('-')[0];
    if (result.failureReason === 'EMPTYINPUT') {
        return resources.addedToCartFailureMessage;
    } else if (result.failureReason === 'CARTACTIONFAILED') {
        if (result.cartActionResult && result.cartActionResult.substatus === 'MAXQUANTITY') {
            // get the quantity of the product in cart
            let elementFoundAt:number = -1;
            const productIdToFind = product?.RecordId;

            for (let i = 0; i < cart!.cart.CartLines!.length; i++) {
                if (cart!.cart.CartLines![`${i}`].ProductId === productIdToFind &&
                    (cart!.cart.CartLines![`${i}`].DeliveryMode || '') === (location && context.actionContext.requestContext.channel?.PickupDeliveryModeCode || '') &&
                    (cart!.cart.CartLines![`${i}`].FulfillmentStoreId || '') === (location?.OrgUnitNumber || '')) {
                    elementFoundAt = i;

                    break;
                }
            }

            let curQuantity: number =  0;

            if (elementFoundAt !== -1) {
                const cartLineToUpdate = {...cart!.cart.CartLines![`${elementFoundAt}`]};
                curQuantity = cartLineToUpdate.Quantity || 0;
            }

            // get the quantity limit for the product
            const maxQuantityForCartLineItem = context.app.config.maxQuantityForCartLineItem;
            const availableQty = productAvailability?.AvailableQuantity;

            let quantityLimit: number;

            if (maxQuantityForCartLineItem === undefined && availableQty === undefined) {
                quantityLimit = 10;
            } else if (availableQty === undefined) {
                quantityLimit = maxQuantityForCartLineItem;
            } else if (maxQuantityForCartLineItem === undefined) {
                quantityLimit = availableQty;
            } else {
                quantityLimit = Math.min(maxQuantityForCartLineItem, availableQty);
            }

            return resource.maxQuantityLimitText[`${localeCode}`].replace('{curQuantity}', curQuantity.toString()).replace('{maxQuantity}', quantityLimit.toString());
        } else if (result.cartActionResult && result.cartActionResult.substatus === 'QUANTITYLIMITS') {
            return result.cartActionResult?.errorDetails?.LocalizedMessage;
        }

        return resources.addedToCartFailureMessage;
    }

    return undefined;
}

export function getConfigureErrors(result: ProductDimension[] | undefined, resources: IBtrtsBuyboxResources, isGiftCard?: Boolean):  { [configureId: string]: string | undefined } {
    if (!result) {
        return {};
    }

    const dimensions: { [configureId: string]: string | undefined } = {};

    result.forEach(dimension => {
        dimensions[dimension.DimensionTypeValue] = getDimensionErrorString(dimension.DimensionTypeValue, resources, isGiftCard);
    });

    return dimensions;
}

export function getDimensionErrorString(dimensionTypeValue: number, resources: IBtrtsBuyboxResources, isGiftCard?: Boolean): string {
    switch (dimensionTypeValue) {
        case 1: // ProductDimensionType.Color
            return resources.productDimensionTypeColorErrorMessage;
        case 2: // ProductDimensionType.Configuration
            return resources.productDimensionTypeConfigurationErrorMessage;
        case 3: // ProductDimensionType.Size
            return resources.productDimensionTypeSizeErrorMessage;
        case 4: // ProductDimensionType.Style
            return isGiftCard ? resources.productDimensionTypeAmountErrorMessage : resources.productDimensionTypeStyleErrorMessage;
        default:
            return '';
    }
}