import { SimpleProduct, ProductWarehouseInventoryInformation, ProductPrice, RecommendedElement } from '@msdyn365-commerce/retail-proxy';
import { getByIdsAsync,  getEstimatedAvailabilityAsync, getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { getElementsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/RecommendationsDataActions.g';

import { ICartDataSet } from './components-interfaces-definition';

import { IEshopFeaturedProductsData } from '../eshop-featured-products.data';
import { IEshopFeaturedProductsProps } from '../eshop-featured-products.props.autogenerated';

export async function getProductListFromCuratedList(props: IEshopFeaturedProductsProps<IEshopFeaturedProductsData>):Promise<ICartDataSet> {
    const { context, config } = props;
    const projectDomain = { ChannelId: + context.actionContext.requestContext.apiSettings.channelId, CatalogId: +context.actionContext.requestContext.apiSettings.catalogId };

    let recommendedElements: RecommendedElement[];

    let simpleProductsResults: SimpleProduct[] = [];
    let activePricesResult: ProductPrice[] = [];
    let productsAvailabilityResults: ProductWarehouseInventoryInformation = {};
    let productIds: number[] = [];
    let resultObject: ICartDataSet = {};    

    recommendedElements= await getElementsAsync({ callerContext: context.actionContext }, config.curatedListId, null);

    if (recommendedElements) {
        recommendedElements.map(product=> productIds.push(Number(product.ElementId)));
        simpleProductsResults= await getByIdsAsync({ callerContext: context.actionContext }, context.request.apiSettings.channelId,productIds);
        productsAvailabilityResults= await getEstimatedAvailabilityAsync({ callerContext: context.actionContext }, { ProductIds: productIds, DefaultWarehouseOnly: true });
        activePricesResult= await getActivePricesAsync({ callerContext: context.actionContext, queryResultSettings: {} }, projectDomain,productIds, new Date(), null, [], true);
    }

    resultObject= {
        productIds: productIds,
        // selectedProducts: productSearchResult,
        productsSimpleDetails: simpleProductsResults,
        productsAvailability: productsAvailabilityResults,
        activePrices: activePricesResult
    };

    return resultObject;
}