import classnames from 'classnames';
import React, { useState } from 'react';

import { getPayloadObject, getTelemetryAttributes, IPopupProps, ITelemetryContent, Popup } from '@msdyn365-commerce-modules/utilities';
import MsDyn365, { IComponent, IComponentProps, IGridSettings, IImageSettings, msdyn365Commerce, TelemetryEvent } from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity, ProductDimension, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { PriceComponent } from './btrts-price-component';

export interface IAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct; price?: ProductPrice }> {
    className?: string;
    addToCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;

    imageSettings: IImageSettings;
    gridSettings: IGridSettings;

    isLoading?: boolean;
    isUpdatingDimension?: boolean;
    isLoadingDeliveryOptions?: boolean;
    isUpdatingDeliveryOptions?: boolean;
    isAddServiceItemToCart?: boolean;
    isAddEmailDeliveryItemToCart?: boolean;
    isPriceKeyedIn?: boolean;
    customPriceAmount?: number;
    isOrderQuantityLimitsFeatureEnabled?: boolean;

    dialogStrings?: {
        goToCartText: string;
        continueShoppingText: string;
        headerItemOneText: string;
        headerItemFormatText: string;
        headerMessageText: string;
        freePriceText: string;
        originalPriceText: string;
        currentPriceText: string;
    };

    telemetryContent?: ITelemetryContent;

    isCustomPriceSelected?: boolean;
    maximumKeyInPrice?: number;
    minimumKeyInPrice?: number;
    defaultMaximumKeyInPrice?: number;
    defaultMinimumKeyInPrice?: number;

    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
    changeUpdatingDimension?(isUpdatingDimension: boolean): void;
    changeUpdatingDeliveryOptions?(isUpdatingDeliveryOptions: boolean): void;
}

export declare type ICartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED' | 'INVALIDCUSTOMAMOUNT';

export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;
    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IAddtoCartComponent extends IComponent<IAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps) => void;
}

const onClick = async (_event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps, setDisabled: (disabled: boolean) => void, openModal: (opened: boolean) => void): Promise<void> => {
    const cartError = addToCartError(props);

    let productToAdd = props.data.product;

    if (cartError) {
        propagateError(props, cartError);
        return;
    }

    setDisabled(false);

    if (!(props.getSelectedProduct === undefined)) {
        productToAdd = (await props.getSelectedProduct) || props.data.product;
    }

    const cartState = await getCartState(props.context.actionContext);

    await cartState.addProductToCart({
        product: productToAdd[0],
        count: props.quantity,
        availableQuantity: props.productAvailability?.AvailableQuantity,
        additionalProperties: { orderQuantityLimitsFeatureIsEnabled: props.isOrderQuantityLimitsFeatureEnabled },
        enableStockCheck: props.context.app.config.enableStockCheck,
        isPriceKeyedIn: props.isPriceKeyedIn,
        customPrice: props.customPriceAmount,
        isAddEmailDeliveryItemToCart: props.isAddEmailDeliveryItemToCart
    }).then(result => {
        if (result.status === 'SUCCESS') {
            if (props.dialogStrings && props.context.app.config.addToCartBehavior === 'showModal') {
                setDisabled(false);
                openModal(true);
            } else if (MsDyn365.isBrowser && props.navigationUrl && (props.context.app.config.addToCartBehavior === undefined || props.context.app.config.addToCartBehavior === 'goToCart')) {
                window.location.assign(props.navigationUrl);
            }

            propagateResult(props, result);
        } else {
            propagateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: result });
            setDisabled(false);
        }
    });
};

const AddToCartComponentActions = {
    onClick: onClick
};

const AddToCart: React.FC<IAddToCartComponentProps> = (props: IAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
        await AddToCartComponentActions.onClick(event, props, setDisabled, setModalOpen);
    };

    const priceComponent = props.data.price ? (
        <PriceComponent
            data={{price: props.data.price}}
            context={props.context}
            id={props.id}
            typeName={props.typeName}
            freePriceText={props.dialogStrings?.freePriceText}
            originalPriceText={props.dialogStrings?.originalPriceText}
            currentPriceText={props.dialogStrings?.currentPriceText}
        />) : '';

    const popupProps: IPopupProps = {
        context: props.context,
        className: 'msc-add-to-cart',
        id: props.id,
        typeName: props.typeName,
        data: { product: props.data.product, price: props.data.price },
        dialogStrings: props.dialogStrings,
        imageSettings: props.imageSettings,
        gridSettings: props.context.request.gridSettings,
        productQuantity: props.quantity !== undefined ? props.quantity : 1,
        priceComponent: priceComponent,
        navigationUrl: props.navigationUrl,
        modalOpen: modalOpen,
        setModalOpen: setModalOpen,
        telemetryContent: props.telemetryContent
    };

    const renderModalPopup = <Popup {...popupProps} />;
    const label = getLinkText(props);
    const payload = getPayloadObject(TelemetryEvent.AddToCart, props.telemetryContent!, label, '');
    const attributes = getTelemetryAttributes(props.telemetryContent!, payload);

    return (
        <>
            {renderModalPopup}
            <button
                className={classnames('msc-add-to-cart ', props.className)}
                {...attributes}
                onClick={onClickHandler}
                disabled={props.disabled || disabled || isIntermediateState(props) || shouldShowOutOfStock(props, false)}
            >
                {getLinkText(props)}
            </button>
        </>
    );
};

// Set default props.
AddToCart.defaultProps = { quantity: 1 };

const getLinkText = (props: IAddToCartComponentProps): string => {
    return shouldShowOutOfStock(props, false) ? props.outOfStockText : props.addToCartText;
};

const addToCartError = (props: IAddToCartComponentProps): IAddToCartFailureResult | undefined => {
    const {data, productAvailability, isCustomPriceSelected, customPriceAmount, maximumKeyInPrice, minimumKeyInPrice, defaultMaximumKeyInPrice = 100, defaultMinimumKeyInPrice = 10} = props;

    if (!data || !data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (data.product.Dimensions) {
        const missingDimensions = data.product.Dimensions.filter(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value));

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (productAvailability && productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // When Custom price is selected, if there is no keyed-in price or keyed-in price is out of limit, should return error.
    if (isCustomPriceSelected && (
            !customPriceAmount || customPriceAmount > (maximumKeyInPrice || defaultMaximumKeyInPrice) || customPriceAmount < (minimumKeyInPrice || defaultMinimumKeyInPrice))) {
        return { failureReason: 'INVALIDCUSTOMAMOUNT' };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (props.context.app.config.enableStockCheck === undefined || props.context.app.config.enableStockCheck === false
        || props.isLoading || props.isUpdatingDimension || props.isLoadingDeliveryOptions || props.isUpdatingDeliveryOptions || props.isAddServiceItemToCart) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }
    const includedQuantityNumber = includeCurrentQuantity && props.quantity? props.quantity : 1;

    return (props.productAvailability
        && props.productAvailability.AvailableQuantity !== undefined
        && props.productAvailability.AvailableQuantity >= includedQuantityNumber)
        ? false : true;
};

const isIntermediateState = (props: IAddToCartComponentProps): boolean => {
    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also not in intermediate state
            return false;
        }
    }

    if (!props.isLoading && !props.isUpdatingDimension && !props.isLoadingDeliveryOptions && !props.isUpdatingDeliveryOptions) {
        return false;
    }

    return true;
};

const propagateResult = (props: IAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propagateError = (props: IAddToCartComponentProps, result: IAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

// @ts-ignore
export const AddToCartComponent: React.FunctionComponent<IAddToCartComponentProps> = msdyn365Commerce.createComponent<IAddtoCartComponent>(
    'AddToCart',
    { component: AddToCart, ...AddToCartComponentActions }
);