import React from 'react';

import { ICoreContext, Image } from '@msdyn365-commerce/core';
import { IGridSettings } from '@msdyn365-commerce/core-internal';
import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';

export interface IDonationProductCardProps {
  context: ICoreContext;
  gridSettings: IGridSettings;
  product: ProductSearchResult;
  productPageUrl: string;
  activeCategoryId:  number;
}

/**
 *
 * ProductCard component
 * @extends {React.PureComponent<IProductCardProps>}
 */
export class DonationsProductCard extends React.PureComponent<IDonationProductCardProps> {
  public render(): JSX.Element | null {
    return (
      <li onClick={this._handleOnClick} role='button'>
          <div className='img-container'>
              <Image
                src={this.props.product.PrimaryImageUrl || ''}
                fallBackSrc={getFallbackImageUrl(this.props.product.ItemId, this.props.context.actionContext.requestContext.apiSettings)}
                altText={this.props.product.Name}
                gridSettings={this.props.gridSettings}
                loadFailureBehavior='empty'
              />
          </div>
          <div className='info-container'>
              <p  style={{fontSize:'20px', lineHeight:'26px'}}>{this.props.product.Name}</p>
              <p/>
          </div>
      </li>
    );
  }

  private _handleOnClick = () => {
      window.location.assign(`${this.props.productPageUrl}?productId=${this.props.product.RecordId.toString()}&categoryId=${this.props.activeCategoryId}`);
  }
}

export default DonationsProductCard;