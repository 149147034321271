import React from 'react';
import ReactPaginate from 'react-paginate';

import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { IProductLayoutProps } from './components-interfaces-definition';
import { DonationsProductCard } from './donations-product-card';
import { getUrlSync } from '@msdyn365-commerce/core';
/**
 *
 * BuildDonationsLayout component
 * @extends {React.PureComponent<IProductLayoutProps>}
 */
 export class BuildDonationsLayout extends React.PureComponent<IProductLayoutProps> {
    public state: any = { activePage: 0, pageSize: (this.props.config.numberOfDonationsPerPage&&this.props.config.numberOfDonationsPerPage>0?this.props.config.numberOfDonationsPerPage:9), currentCategoryId: 0 };

    constructor(props: IProductLayoutProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        const { context, productList, gridSettings, simpleProductList, config, activeCategoryId } = this.props;

        if (activeCategoryId&&activeCategoryId>0&&activeCategoryId!=this.state.currentCategoryId&&this.state.activePage>0) {
            this.setState({ activePage: 0, currentCategoryId: activeCategoryId});
        }

        const offset = this.state.activePage * Number(this.state.pageSize);

        const currentPageData = simpleProductList
            .slice(offset, offset + Number(this.state.pageSize))
            .map((item: ProductSearchResult) => (
                    <DonationsProductCard
                        key={item.RecordId}
                        context={context}
                        gridSettings={gridSettings}
                        product={item}
                        productPageUrl={ getUrlSync((config.productPageUrl!?config.productPageUrl!:''), this.props.context.actionContext) || ''}
                        activeCategoryId={ activeCategoryId! }
                    />
                )
            );

        const pageCount = Math.ceil(productList.length / this.state.pageSize);

        const _handlePageClick = (data: any) => {
            this.setState({ activePage: data.selected, currentCategoryId: activeCategoryId});
        }

        // TODO: Make page size customizable on frontend using a dropdown.

        return (
            <div className='btrts-product-list-container'>
                <ul className='btrts-product-list'>{currentPageData}</ul>
                { productList.length>0&&(
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={_handlePageClick}
                        containerClassName={'btrts-product-list-pagination'}
                        activeClassName={'active'}
                    />)
                }
            </div>
        );
    }
}

export default BuildDonationsLayout;