import React from 'react';

import { ICoreContext, Image } from '@msdyn365-commerce/core';
import { IGridSettings } from '@msdyn365-commerce/core-internal';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getFallbackImageUrl, getProductUrlSync } from '@msdyn365-commerce-modules/retail-actions';

export interface IProductCardProps {
  context: ICoreContext;
  gridSettings: IGridSettings;
  product: SimpleProduct;
  hasExpired: boolean;
  isRegistered: boolean;
  unavailableText: string;
  registeredText: string;
}

/**
 *
 * ProductCard component
 * @extends {React.PureComponent<IProductCardProps>}
 */
export class ProductCard extends React.PureComponent<IProductCardProps> {
  public render(): JSX.Element | null {
    const style = this.props.hasExpired ? 'ecomm-product-card not-available' : 'ecomm-product-card';

    return (
      <li className={style} onClick={this._handleOnClick} role='button'>
        <div className='img-container'>
          {this.props.hasExpired && <p>{this.props.unavailableText}</p>}
          <Image
            src={this.props.product.PrimaryImageUrl || ''}
            fallBackSrc={getFallbackImageUrl(this.props.product.ItemId, this.props.context.actionContext.requestContext.apiSettings)}
            altText={this.props.product.Name}
            gridSettings={this.props.gridSettings}
            loadFailureBehavior='empty'
          />
          {this.props.isRegistered && <div>{this.props.registeredText}</div>}
        </div>
        <div className='info-container'>
          <p>{this.props.product.Name}</p>
          <p>{this.props.product.Price == 0 ? '' : '$' + this.props.product.Price}</p>
        </div>
      </li>
    );
  }

  private _handleOnClick = () => {
    if (!this.props.hasExpired) {
      window.location.assign(getProductUrlSync(this.props.product, this.props.context.actionContext, undefined));
    }
  }
}

export default ProductCard;