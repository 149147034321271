import React from 'react';
import ReactPaginate from 'react-paginate';

import { Image } from '@msdyn365-commerce/core';
import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { ProductSearchResult, SimpleProduct, ProductWarehouseInventoryInformation, ProductPrice } from '@msdyn365-commerce/retail-proxy';
import { searchByCategoryAsync, getByIdsAsync,  getEstimatedAvailabilityAsync, getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { ICartDataSet, IProductLayoutProps } from './components-interfaces-definition';
import { AddtoCartButton } from './btrts-add-to-cart.component';
import { IProductListData } from '../product-list.data';
import { IProductListProps } from '../product-list.props.autogenerated';

export async function getProductListFromCategory(props: IProductListProps<IProductListData>, categoryId: number):Promise<ICartDataSet> {
    const { context } = props;
    const projectDomain = { ChannelId: + context.actionContext.requestContext.apiSettings.channelId, CatalogId: +context.actionContext.requestContext.apiSettings.catalogId };

    let productSearchResult: ProductSearchResult[];
    let simpleProductsResults: SimpleProduct[] = [];
    let activePricesResult: ProductPrice[] = [];
    let productsAvailabilityResults: ProductWarehouseInventoryInformation = {};
    let productIds: number[] = [];
    let resultObject: ICartDataSet = {};    

    productSearchResult= await searchByCategoryAsync({ callerContext: context.actionContext },context.request.apiSettings.channelId,context.request.apiSettings.catalogId,categoryId);

    if (productSearchResult) {
        productSearchResult.map(product=> productIds.push(product.RecordId));
        simpleProductsResults= await getByIdsAsync({ callerContext: context.actionContext }, context.request.apiSettings.channelId,productIds);
        productsAvailabilityResults= await getEstimatedAvailabilityAsync({ callerContext: context.actionContext }, { ProductIds: productIds, DefaultWarehouseOnly: true });
        activePricesResult= await getActivePricesAsync({ callerContext: context.actionContext, queryResultSettings: {} }, projectDomain,productIds, new Date(), null, [], true);
    }

    resultObject= {
        productIds: productIds,
        selectedProducts: productSearchResult,
        productsSimpleDetails: simpleProductsResults,
        productsAvailability: productsAvailabilityResults,
        activePrices: activePricesResult
    };

    return resultObject;
}

/**
 *
 * CategoryFilterView component
 * @extends {React.PureComponent<IProductLayoutProps>}
 */
 export class BuildProductLayout extends React.PureComponent<IProductLayoutProps> {
    public state: any = { activePage: 0, pageSize: (this.props.config.numberOfOfferingsPerPage&&this.props.config.numberOfOfferingsPerPage>0?this.props.config.numberOfOfferingsPerPage:6), currentCategoryId: 0 };

    constructor(props: IProductLayoutProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        const { resources, context, config, productList,
                simpleProductList, productsAvailability,
                productPriceList, cart, telemetryContent, gridSettings,
                activeCategoryId
            }: any = this.props;

        if (activeCategoryId&&activeCategoryId>0&&activeCategoryId!=this.state.currentCategoryId&&this.state.activePage>0) {
            this.setState({ activePage: 0, currentCategoryId: activeCategoryId});
        }

        const offset = this.state.activePage * Number(this.state.pageSize);

        const currentPageData = productList
            .slice(offset, offset + Number(this.state.pageSize))
            .map((item: ProductSearchResult) =>
                (
                <li key={item.RecordId}>
                    <div className='img-container'>
                        <Image
                            src={item.PrimaryImageUrl || ''}
                            fallBackSrc={getFallbackImageUrl(item.ItemId, context.actionContext.requestContext.apiSettings)}
                            altText={item.Name}
                            gridSettings={gridSettings}
                            loadFailureBehavior='empty'
                        />
                        <AddtoCartButton
                            product={item}
                            simpleProductList={simpleProductList}
                            productsAvailability={productsAvailability}
                            productPriceList={productPriceList}
                            telemetryContent={telemetryContent}
                            cart={cart}
                            resources={resources}
                            context={context}
                            config={config}
                        />
                    </div>
                    <div className='info-container'>
                        <p style={{fontSize:'20px', lineHeight:'26px'}}>{item.Name}</p>
                        <p style={{fontSize:'20px', lineHeight:'26px'}}>{item.Price} SGD</p>
                    </div>
                </li>
                )
            );

        const pageCount = Math.ceil(productList.length / this.state.pageSize);

        
        const _handlePageClick = (data: any) => {
            this.setState({ activePage: data.selected, currentCategoryId: activeCategoryId});
        }

        // TODO: Make page size customizable on frontend using a dropdown.

        return (
            <div className='btrts-product-list-container'>
                <ul className='btrts-product-list'>{currentPageData}</ul>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={_handlePageClick}
                    containerClassName={'btrts-product-list-pagination'}
                    activeClassName={'active'}
                    forcePage={this.state.activePage}
                />
            </div>
        );
    }    
 }

 export default BuildProductLayout;