import React from 'react';

// import { Image } from '@msdyn365-commerce/core';
// import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { ProductSearchResult, SimpleProduct, ProductWarehouseInventoryInformation, ProductPrice } from '@msdyn365-commerce/retail-proxy';
import { searchByCategoryAsync, getByIdsAsync, getEstimatedAvailabilityAsync, getActivePricesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { ICartDataSet, IProductLayoutProps } from './components-interfaces-definition';
import { AddtoCartButton } from './btrts-add-to-cart.component';
import { IBtrtsKioskv3ProductListData } from '../btrts-kioskv3-product-list.data';
import { IBtrtsKioskv3ProductListProps } from '../btrts-kioskv3-product-list.props.autogenerated';
import { getCartState } from '@msdyn365-commerce/global-state';
import { removeCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { generateImageUrl } from '@msdyn365-commerce-modules/retail-actions';

export async function getProductListFromCategory(props: IBtrtsKioskv3ProductListProps<IBtrtsKioskv3ProductListData>, categoryIds: number[]): Promise<ICartDataSet[]> {
    const { context } = props;
    const projectDomain = { ChannelId: + context.actionContext.requestContext.apiSettings.channelId, CatalogId: +context.actionContext.requestContext.apiSettings.catalogId };

    let resultObject: ICartDataSet[] = [];
    for (const categoryId of categoryIds) {
        let productSearchResult: ProductSearchResult[];
        let simpleProductsResults: SimpleProduct[] = [];
        let activePricesResult: ProductPrice[] = [];
        let productsAvailabilityResults: ProductWarehouseInventoryInformation = {};
        let productIds: number[] = [];

        productSearchResult = await searchByCategoryAsync({ callerContext: context.actionContext }, context.request.apiSettings.channelId, context.request.apiSettings.catalogId, categoryId);

        if (productSearchResult) {
            productSearchResult.map(product => productIds.push(product.RecordId));
            simpleProductsResults = await getByIdsAsync({ callerContext: context.actionContext }, context.request.apiSettings.channelId, productIds);
            productsAvailabilityResults = await getEstimatedAvailabilityAsync({ callerContext: context.actionContext }, { ProductIds: productIds, DefaultWarehouseOnly: true });
            activePricesResult = await getActivePricesAsync({ callerContext: context.actionContext, queryResultSettings: {} }, projectDomain, productIds, new Date(), null, [], true);
        }

        resultObject.push({
            categoryId: categoryId,
            productIds: productIds,
            selectedProducts: productSearchResult,
            productsSimpleDetails: simpleProductsResults,
            productsAvailability: productsAvailabilityResults,
            activePrices: activePricesResult
        });
    }

    return resultObject;
}

/**
 *
 * CategoryFilterView component
 * @extends {React.PureComponent<IProductLayoutProps>}
 */
export class BuildProductLayout extends React.PureComponent<IProductLayoutProps> {
    public state = {
        allProducts: this.props.allProducts,
        productList: this.props.productList,
        simpleProductList: this.props.simpleProductList,
        productsAvailability: this.props.productsAvailability,
        productPriceList: this.props.productPriceList,
        activePage: 0,
        pageSize: (this.props.config.numberOfOfferingsPerPage && this.props.config.numberOfOfferingsPerPage > 0 ? this.props.config.numberOfOfferingsPerPage : 6),
        currentCategoryId: 0,
        activeCategoryId: 0
    };

    constructor(props: IProductLayoutProps) {
        super(props);
    }

    public render(): JSX.Element | null {
        const { resources, context, config, productList,
            simpleProductList, productsAvailability,
            productPriceList, cart, telemetryContent, //gridSettings
        }: any = this.props;

        if (this.state.productList.length === 0) {
            this.setState({ activeCategoryId: this.props.allProducts.length > 0 ? this.props.allProducts[0].categoryId : 0, allProducts: this.props.allProducts, productList: productList, simpleProductList: simpleProductList, productsAvailability: productsAvailability, productPriceList: productPriceList });
        }

        const offerings = this.props.hierarchy.find(h => h.NameTranslations?.find(t => t.Text === 'Offerings'));
        const menu = offerings?.Children.map(item => {
            const imgUrl = generateImageUrl(`Categories/${item.NeutralizedName} kioskv3.png`, this.props.context.request.apiSettings);
            return <li key={item.RecordId}>
                <button type='button' className={`alt ${this.state.activeCategoryId === item.RecordId ? 'focus' : ''}`} onClick={this._handleOnClick(item.RecordId)}>
                    <img src={imgUrl}></img>
                    {item.Name}
                </button>
            </li>
        });

        if (this.state.activeCategoryId && this.state.activeCategoryId > 0 && this.state.activeCategoryId != this.state.currentCategoryId && this.state.activePage > 0) {
            this.setState({ activePage: 0, currentCategoryId: this.state.activeCategoryId });
        }

        const currentPageData = this.state.productList
            .map((item: ProductSearchResult) =>
            (
                <li key={item.RecordId}>
                    <div className='img-container'>
                        <AddtoCartButton
                            product={item}
                            simpleProductList={this.state.simpleProductList}
                            productsAvailability={this.state.productsAvailability as any}
                            productPriceList={this.state.productPriceList}
                            telemetryContent={telemetryContent}
                            cart={cart}
                            resources={resources}
                            context={context}
                            config={config}
                        />
                    </div>
                </li>
            )
            );

        return (
            <div className='row'>
                <div className='col-2 col-sm-2 col-md-2 col-lg-2'>
                    <div className='btrts-side-menu' style={{ backgroundImage: `url(${this.props.config.sideMenuImage.src})` }}>
                        <p>{this.props.config.selectionTitle}</p>
                        <ul>{this.state.activeCategoryId > 0 && menu}</ul>
                        <button className='start-over' onClick={this._endSession(this.props.config.homeUrl)}><p>{this.props.config.startOverTitle}</p></button>
                    </div>
                </div>
                <div className='col-10 col-sm-10 col-md-10 col-lg-10'>
                    <div className='btrts-product-list-container'>
                        <img src={this.props.config.hallImage.src}></img>
                        <ul className='btrts-product-list'>{currentPageData}</ul>
                    </div>
                </div>
            </div>
        );
    }

    private _handleOnClick = (categoryId?: number) => async () => {
        const products = this.state.allProducts.find(p => p.categoryId === categoryId);
        this.setState({ activeCategoryId: categoryId, productList: products?.selectedProducts, simpleProductList: products?.productsSimpleDetails, productsAvailability: products?.productsAvailability, productPriceList: products?.activePrices });
    }

    private _endSession = (homeURL: string) => async () => {
        this.setState({ activeCategoryId: 0 });

        const actionContext = this.props.context.actionContext;

        try {
            const cartState = await getCartState(actionContext);
            const cart = cartState.cart;
            const cartId = cart.Id.toString();
            let cartLinesId: string[] = [];

            if (cart && cart.CartLines && cart.CartLines.length) {
                cart.CartLines.map(line => {
                    cartLinesId.push(line.LineId!);
                });

                if (cartLinesId.length > 0) {
                    await removeCartLinesAsync({ callerContext: actionContext }, cartId, cartLinesId);
                }
            }
        } catch (e) {
            actionContext.telemetry.error(e.message);
            actionContext.telemetry.debug(`[getCartState]${e.message}`);
            console.log('[getCartState] ', e.message);
        }

        window.location.assign(homeURL);
    }
}

export default BuildProductLayout;